
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useSnackbar } from "notistack";
import "./index.css";
import "./styles.css";
import styles from "../styles";
import _ from "lodash";
import moment from 'moment';
import {
  handleServerErrors
} from "../../utiles/helpers";
import {
  Typography,
  Button
} from "@material-ui/core";
import { AddButton, AllocateButton} from "../common/Buttons";
import ContentHeader from "../common/ContentHeader";
import CrudDialog from "../common/CrudDialog";
import Loader from "../common/Loader";
import InfoModal from "components/Common/AlertDialog/info_dialogue";
import { ExportButton, ImportButton } from "components/Common/Buttons";
  const QuestionsTab = () => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();

    // State and functions as defined above
    const [buttonDisable, setButtonDisable] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [isQuestionsChanged, setIsQuestionsChanged] = useState(false);
    const [isLoading, setIsLoading]= useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isMutating, setIsMutating] = useState(false);
    const [importError, setImportError] = useState([]);
    const [isInfo, setIsInfo] = useState(false);

    const MappedTypes={
      'Email':"Email",
      "Date":"Date",
      "Mobile_Number":"Phone Number",
      "Number":"Number",
      "Text":"Text",
      "SSN":"SSN"
    }
    const formFields= [
        [
          {
            key: "question",
            columnName: "Question",
            label: "Question",
            type: "text",
            visible: true,
            required: true,
          },
        ],
          [{
            key: "question_type",
            columnName: "Question Type",
            label: "Question type",
            visible: true,
            required: true,
            type: "select",
            
            options: [{ label: "Email", value: 'Email' },
            { label: "Date", value: 'Date' },
            { label: "Phone Number", value: 'Mobile_Number' },
            { label: "Number", value: 'Number' },
            { label: "Text", value: 'Text' },
            { label: "SSN", value: 'SSN' }],
          },
        ]
      ];


      const handleAdd = (data) => {
        data.id = data.id ? data.id : (Math.random() * 1000000).toFixed(0);
        data.order = data.order ? data.order : questions.length+1;
        setQuestions([...questions, data])
        setIsQuestionsChanged(true);
        setAddModal(false)
        console.log(questions)
      
      };

      const handleSave = () => {
        setIsLoading(true)
        setIsSaving(true)
        const question_data = {
          "questions":questions
        }
        const post_url = isQuestionsChanged?`ui_form`:`ui_form?sync_only=True`
        window.axiosIns
          .post(post_url, question_data)
          .then(() => {
            enqueueSnackbar("Form was saved and synced successfully.");
            getQuestionForm();
            setIsLoading(false)
            setIsSaving(false)
          })
          .catch((err) => {
            if (err.response.data.detail) {
              enqueueSnackbar(err.response.data.detail);

            } else {
              handleServerErrors(
                err,
                enqueueSnackbar,
                "Could not save form. Try again."
              );
            }
            
            setIsLoading(false)
            setIsSaving(false)
          })
          .then(() => {
            setIsQuestionsChanged(false)
            setIsLoading(false);
            
            setIsSaving(false)
          })
      };

      const getQuestionForm = async (data) =>{
        try {
          setIsLoading(true);
          const params = {
            is_active: true,
            language: 'English'
          };
          const { data } = await window.axiosIns("/ui_form", { params });
          const question_data = []
          if (data?.results?.length >0){
            const questions_res = data?.results['0'].question_list
            questions_res.map((question)=>question_data.push({
              'id': question.id,
              'question_type': question.question_type,
              'question':question.question,
              'order':question.order
            }))
          }

          setQuestions(question_data);
        } catch (err) {
        } finally {
          setIsLoading(false);
        }
      }

      useEffect(()=>{
        getQuestionForm()
      },[])

      const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return;
        
        const updatedList = [...questions];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      
        // Update the order property for each question
        const orderedQuestions = updatedList.map((item, index) => ({
          ...item,
          order: index + 1
        }));
      
        setQuestions(orderedQuestions);
        setIsQuestionsChanged(true);
      };
    
    
      const addQuestion = (newQuestion) => {
        setQuestions([
          ...questions,
          { ...newQuestion, id: Math.max(...questions.map(q => q.id)) + 1, order: questions.length + 1 }
        ]);
        setIsQuestionsChanged(true);
      };
    
      const editQuestion = (id, updatedContent) => {
        const updatedQuestions = questions.map(q =>
          q.id === id ? { ...q, ...updatedContent } : q
        );
        setQuestions(updatedQuestions);
        setIsQuestionsChanged(true);
      };
      const handleDelete = (id) => {
        const updatedQuestions = questions.filter(q => q.id !== id);
        setQuestions(updatedQuestions);
        setIsQuestionsChanged(true);
      };
  
      const handleClick = (event) => {
        hiddenFileInput.current.value = null;
        hiddenFileInput.current.click();
      };
    
      const FormImport = async (event) => {

        const fileUploaded = event.target.files[0];
        const formData = new FormData();
        formData.append('template', fileUploaded);
    
        setIsMutating(true);
        window.axiosIns
          .post(`/ui_form/import_data`, formData)
          .then((resp) => {
            const {data: {data}} = resp;
            const message = resp.data.message;
            if (message === 'There was some error while importing languages') setImportError(data); setIsInfo(true);
  
            const errors = [];
            debugger;
            if (data.records_failed > 0) {
              Object.keys(data.failed_entries).forEach((key) => {
                errors.push(`Row ${key} : ${data.failed_entries[key]}`)
              })
    
              setImportError(errors);
              setIsInfo(true);
            }
            enqueueSnackbar(resp?.data?.message || "Questions Added successfully.", { autoHideDuration: 3000 });
            setTimeout(() => {
              setIsMutating(false);
            }, 200);
          })
          .catch((err) => {
            if (err.response.data.detail) {
              enqueueSnackbar(err.response.data.detail);
            } else {
              handleServerErrors(
                err,
                enqueueSnackbar,
                "Could not add Questions. Try again."
              );
            }
            setIsMutating(false);
          })
          .then(() => {
            getQuestionForm();
          });
    
      };
  
  const formDataExport = () => {
    
        window.axiosIns("/ui_form/export", {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Questions-Summary-${moment().format("MM-DD-YYYY")}.xlsx`);
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => console.log(error));
      }
    
        
      
  const hiddenFileInput = React.useRef(null);
  
    return (
        <div id="sa-modules-wrapper" className={classes.wrapper}>
          <ContentHeader
        description="Add Questions for form here. You can add upto 10 questions."
      />

      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled = {questions.length>10}
          />
          <AllocateButton
            className="mr-3"
            label= {isQuestionsChanged?'Save and Sync':'Sync'}
            onClick={() => handleSave()}
            loading={isSaving}
            
          />
        <ExportButton
            className="mr-3"
            label="Export"
            onClick={() => formDataExport()}
          />
          <></>
          <>
          <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={FormImport}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
          </div>
          </div>
          {isLoading?<Loader visible={true}/>:
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className="list-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {questions.length === 0 ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20}}>No questions added.</div>
                    : questions.map((question, index) => (
                      <Draggable key={question.id} draggableId={question.id.toString()} index={index}>
                        {(provided) => (
                          <div
                            className="item-container"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <DragIndicatorIcon color="primary" />
                                <Typography variant="body1" style={{ marginLeft: 20 }}>
                                  {index + 1}.
                                </Typography>
                                <span style={{ margin: 10, marginLeft: 20 }}>
                                  {question.question}
                                </span>
                              </div>

                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Typography variant="body1" style={{ marginRight: 20 }}>
                                  Type: {MappedTypes[question.question_type]}
                                 
                                </Typography>
                                {/* Additional elements like delete button can go here */}
                                <Button
                                  size="small"
                                  onClick={() => handleDelete(question.id)}
                                  style={{
                                    fontSize: "0.8rem",
                                    color: "red",
                                    margin: 10,
                                    marginLeft: 20,
                                    padding: 8
                                  }}
                                  color="primary"
                                  variant="outlined"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
}

          <CrudDialog
          title="Add Questions"
          okText="Add"
          fields={formFields}
          onFieldChange={(values, callBackValue) => {
            setButtonDisable(false);
          }}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
            setButtonDisable(true);
          }}
        />
        <InfoModal 
          title="Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />

      </div>  
    );
  };
  
  export default QuestionsTab;
  